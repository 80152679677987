export const app_i18n = {
  text1:"قم بمسح الجزء الخلفي من بطاقة الهوية الخاصة بك أو الصفحة من جواز سفرك التي تحتوي على صورتك.",
  text2: "هل لديك مشاكل مع الماسح الضوئي؟",
  text3: "إعادة المحاولة",
  text4: "عملية يدوية",
  text5: "مرحباً!!",
  text6: "اختر تاريخ الإقامة",
  text7: "بريدك الإلكتروني",
  text8: "استمرار",
  text9: "دعنا نتابع! تبقى القليل",
  text10: "أهلاً وسهلاً",
  text11: "مرحباً بك",
  text12: "هاتف",
  text13: "للمتابعة في العملية، يجب عليك قبول شروطنا وأحكامنا.",
  text14: "أوافق على الشروط والأحكام",
  text15: "استمرار",
  text16: "يمكنك التوقيع في المربع الرمادي الذي تراه على الشاشة",
  text17: "مسح",
  text18: "حفظ التوقيع",
  text19: "يرجى كتابة توقيعك",
  text20: "تم تسجيل الدخول بنجاح",
  text21: "شكراً جزيلاً، لقد أرسلنا لك نسخة من الوثيقة الموقعة على WhatsApp",
  text22: "هل تحتاج إلى إجراء تسجيل دخول آخر؟",
  text23: "إعادة البدء",
  text24: "مرحباً بك 🤗",
  text25: "😃 يرجى إدخال البيانات التالية",
  text26: "الاسم",
  text27: "الكنية",
  text28: "اختر الجنس",
  text29: "الجنس",
  text30: "ذكر",
  text31: "أنثى",
  text32: "تاريخ الميلاد",
  text33: "دعنا نتابع! تبقى القليل 😉",
  text34: "اختر تاريخ الإقامة.",
  text35: "البريد الإلكتروني",
  text36: "أدخل الهوية",
  text37: "نوع الوثيقة",
  text38: "هوية",
  text39: "جواز السفر",

  text40: "أدخل رقم الوثيقة",
  text41: "يجب عليك اختيار بلد",
  text42: "البلدان",
  text43: "قائمة الخدمات المتاحة للإقامة",
  text44: "جارٍ تحميل الخدمات ...",
  text45: "لا توجد خدمات متاحة",
  text46: "تم إرسال طلب الخدمة بنجاح",
  text47: "سيتصل بك أحد الوكلاء لتأكيد طلب الخدمة.",
  text48: "العودة",
  text49: "تم إرسال طلب الخدمة بنجاح، سيتم الاتصال بك من قبل مشرف في لحظة.",
  text50: "السعر",
  text51: "وصف الخدمة",
  text52: "طلب",
  text53: "إرسال طلب الخدمة",
  text54: "عند إرسال الطلب، سيتصل بك مشرف.",
  text55: "إرسال",

  text56: "تم إرسال طلب الخدمة بنجاح.",
  text57: "خطأ، لم يمكن طلب الخدمة",
  text58: "جارٍ إرسال بيانات تسجيل الوصول، يرجى الانتظار...",
  text59: "جارٍ التحميل...",
  text60: "تم إكمال تسجيل الوصول بنجاح.",
  text61: `🎁 بالنقر هنا، يمكنك مشاهدة جميع خدماتنا المتاحة في الفندق`,
  text62: "تسجيل الدخول",
  text63: "أفضل عدم قوله",
  text64: "آخر",
  text65: "البلد",
  text66: "خطأ",
  text67: "تم تعطيل الإقامة.",
  text68: "قام المالك بإيقاف تسجيل الوصول.",
  text69: "اختر التاريخ",
  text70: "من - إلى",

  text71: "اختر خيارًا",
  text72: "اختر البلد *",
  text73: "هل أنت متأكد أنك تريد إعادة بدء العملية",
  text74: "سوف تفقد البيانات المحفوظة",
  text75: "بيانات تسجيل الوصول",
  text76: "توقيع تسجيل الوصول",
  text77: "موقع",
  text78: "مرحباً",
  text79: "نأسف، حدث خطأ أثناء تسجيل الخروج",
  text80: "إلغاء العملية.",
  text81: "إن إنشاء أول إقامة أمر إلزامي لضمان حسن سير النظام.",
  text82: "إلغاء والخروج",
  text83: "الاستمرار في التعديل",
  text84: "البحث في السجلات",
  text85: "لا توجد سجلات",
  text86: "سابق",

  text87: "التالي",
  text88: "هل تريد إعادة المحاولة أو القيام بالعملية يدويًا؟",
  text89: "جارٍ التحميل ...",
  text90: "الخدمات",
  text91: "تاريخ إصدار الوثيقة",
  text92: "حقل تاريخ إصدار الوثيقة إلزامي",
  p93: `جارٍ تحميل...`,
  p94: `بيانات الحجز`,
  p95: `مرحباً`,
  p96: `للاستمرار في تسجيل الوصول، يرجى إدخال البيانات التالية:`,
  p97: `هذه العملية تجريبية، لن يتم حفظ أي من بياناتك`,
  p98: `رمز الأمان:`,
  p99: `رمز الأمان للإقامة`,
  p101: `أدخل رمز الأمان`,
  p102: `رمز الأمان غير صحيح، يرجى التحقق والمحاولة مرة أخرى.`,
  p103: `أو يمكنك أيضًا`,
  p104: `تصفية بواسطة رمز تسجيل الوصول`,
  p105: `استمرار`,

  p106: `رمز تسجيل الوصول`,
  p107: `أدخل رمز تسجيل الوصول`,
  p108: `رمز تسجيل الوصول غير مسجل في منصتنا، يرجى التحقق والمحاولة مرة أخرى`,
  p109: `أدخل رمز الإقامة`,
  p110: `تسجيل الوصول البيئي`,
  p111: `مرحباً!`,
  p112: `يرجى الاحتفاظ بـ`,
  p112_1: `بطاقة الهوية أو جواز السفر`,
  p112_2: `لإجراء العملية`,
  p113: `يجب عليك قبول`,
  p114: `أذونات الكاميرا 📷`,
  p115: `التي تحتاجها ليعمل ماسحنا الضوئي بشكل صحيح.`,
  p116: `استمرار`,
  p117: `اختر اللغة`,
  p118: `أدخل رمز الأمان`,
  p119: `صفحة جواز السفر أو الجزء الخلفي من بطاقة الهوية الخاصة بك`,
  p120: `الهوية`,
  p121: `بطاقة الهوية`,

  p122: `أو صفحة جواز السفر حيث توجد صورتك`,
  p123: `لاستخدام الماسح الضوئي، من الضروري منح الأذونات اللازمة للكاميرا.`,
  p124: `التحقق من الأمان 🔐`,
  p125: `للتحقق من رقمك، أرسلنا رمزًا إلى`,
  p126: `واتساب`,
  p127: `اكتبها هنا:`,
  p128: `أدخل الرمز`,
  p129: `الرمز غير صحيح، يرجى التحقق والمحاولة مرة أخرى`,
  p130: `العودة`,
  p131: `استمرار`,
  p132: `تاريخ الدخول`,
  p133: `تاريخ الخروج`,
  p134: `تاريخ الانتهاء`,
  p135: `الجنسية`,
  p136: `عند النقر على`,
  p137: `توافق على توقيع النموذج باسمك، عقد قصير الأجل`,
  p138: `سياسات الخصوصية`,
  p139: `حفظ التوقيع`,
  p140: `معلومات الحجز`,
  p141: `الحجز:`,
  p142: `الدخول`,

  p143: `الخروج`,
  p144: `الدفع`,
  p145: `مدفوعة`,
  p146: `جارٍ التحقق من الدفع`,
  p147: `قم بتسجيل الوصول`,
  p148: `الحجز`,
  p149: `الخدمات`,
  p150: `التحقق من الهوية`,
  p151: `قم بتحميل سيلفي لوجهك، مع إضاءة جيدة من فضلك`,
  p152: `في هذه الشاشة يمكنك تحميل صورة للجانب <strong>الأمامي من بطاقة الهوية`,
  p153: `في هذه الشاشة يمكنك تحميل صورة للجانب <strong>الخلفي من بطاقة الهوية`,
  p154: `فتح الكاميرا`,
  p155: `التقاط مرة أخرى`,
  p156: `التحقق من الهوية`,
  p157: `استمرار`,
  p158: `خطأ في التحقق من هويتك.`,
  p159: `يرجى المحاولة مرة أخرى. يمكنك التقاط الصور في منطقة مضاءة جيدًا وركز على وثيقتك.`,
  p160: `العملية إلزامية*`,
  p161: `إعادة المحاولة`,
  p162: `جارٍ التحقق من الهوية.`,
  p163: `يرجى الانتظار ....`,
  p164: `تم التحقق من الهوية بنجاح`,

  p165: `لقد تحقق من هويته 🪪 بنجاح، يتبقى القليل لإكمال عملية تسجيل الوصول 😎`,
  p166: `للاستشارة`,
  p167: `لطلب هذه الخدمة يجب عليك إجراء تسجيل الوصول.`,
  p168: `التقدم`,
  p169: `تاريخ انتهاء الوثيقة`,
  p170: `تاريخ الدخول`,
  p171: `تاريخ الخروج`,
  p172: `الهاتف`,
  p173: `لم يتمكن من مسح وثيقتك.`,
  p174: `العنوان لا ينتمي إلى حجز في النظام`,
  p175: `معلومات تسجيل الوصول`,
  p176: `الدعم`,
  p177: `الإسبانية (إسبانيا)`,
  p178: `الإنجليزية`,
  p179: `العربية`,
  p180: `بطاقة الهوية`,
  p181: `جواز السفر`,
};
