export const app_i18n = {
    text1: "Scan the back of your ID card or the page of your passport where your photo is located",
    text2: "Are you having trouble with the scanner?",
    text3: "Retry",
    text4: "Manual Process",
    text5: "Welcome!!",
    text6: "Select the lodging date",
    text7: "Your email",
    text8: "Continue",
    text9: "Let's go!, almost done",
    text10: "Welcome",
    text11: "Welcome",
    text12: "Phone",
    text13: "To continue with the process you must accept our terms and conditions.",
    text14: "I accept the terms and conditions",
    text15: 'Continue',
    text16: "You can sign in the gray box you see on the screen",
    text17: "Clear",
    text18: "Save Signature",
    text19: "Please write your signature",
    text20: "Check-in successfully completed",
    text21: "Thank you very much, we have sent a copy of the signed document to your WhatsApp",
    text22: "Need to perform another check-in?",
    text23: "Start over",
    text24: "Welcome  🤗",
    text25: "😃 Please enter the following data",
    text26: "Name",
    text27: "Last Name",
    text28: "Select gender",
    text29: "Gender",
    text30: "Male",
    text31: "Female",
    text32: "Date of birth",
    text33: "Let's go!, almost there 😉",
    text34: "Select the lodging date.",
    text35: "Email",
    text36: "Enter ID",
    text37: "Document type",
    text38: "ID",
    text39: "PASSPORT",
    text40: "Enter document number",
    text41: "You must select a country",
    // here
    text42: "Countries",
    text43: "List of available accommodation services",
    text44: "Loading services ...",
    text45: "No services available",
    text46: "Service request sent successfully",
    text47: "An agent will contact you to finalize the service request.",
    text48: "Back",
    text49: "Service request sent successfully, you will be contacted by a supervisor shortly.",
    text50: "Price",
    text51: "Service Description",
    text52: "Request",
    text53: "Send service request",
    text54: "By sending the request a supervisor will contact you",
    text55: "Send",
    text56: "Service request sent successfully.",
    text57: "Error, could not request service",
    text58: "Sending Check-in data please wait...",
    text59: "Loading...",
    text60: "completed the check-in successfully.",
    text61: `🎁 Click here to see all our available services at the hotel`,
    text62: "Entry Registration",
    text63: "Prefer not to say",
    text64: "Other",
    text65: "Country",
    text66: "Error",
    text67: "Accommodation deactivated.",
    text68: "The owner has deactivated the check-ins.",
    text69: "Select date",
    text70: "From - To",
    text71: "Select an option",
    text72: "Select the country *",
    text73: "Are you sure you want to restart the process",
    text74: "You will lose saved data",
    text75: "Check-in data",
    text76: "Check-in signature",
    text77: "Signed",
    text78: "Welcome",
    text79: "Sorry, an error occurred while logging out",
    text80: "Cancel the process.",
    text81: "Creating the first accommodation is mandatory for the proper functioning of the system.",
    text82: "Cancel and Exit",
    text83: "Continue with editing",
    text84: "Search...",
    text85: "No records found",
    text86: "Previous",
    text87: "Next",
    text88: "Do you want to retry or do the manual process?",
    text89: "Loading ...",
    text90: "Services",
    text91: "Document Issue Date",
    text92: "The Document Issue Date field is required",
    p93: `loading your...`,
    p94: `Reservation Data`,
    p95: `Welcome`,
    p96: `To continue with the check-in please enter the following
    data:`,
    p97: `This process is a demonstration, none of your data will be saved`,
    p98: `Security Code:`,
    p99: `Accommodation Security Code`,
    p101: `Enter the security code`,
    p102: `The security code is incorrect, please verify and try
    again.`,
    p103: `Or you can also`,
    p104: `Filter by check-in code`,
    p105: `Continue`,
    p106: `Check-in code`,
    p107: `Enter the check-in code`,
    p108: `The check-in code is not registered in our platform, please check and try again.`,
    p109: `Enter accommodation code`,
    p110: `Eco-friendly Check-in`,
    p111: `Welcome!`,
    p112: `Please have your`,
    p112_1:`ID or Passport`,
    p112_2:`ready to proceed`,
    p113: `You'll need to accept the`,
    p114: ` camera permissions 📷`,
    p115: ` they're necessary for our scanner to work properly.`,
    p116: `Continue`,
    p117: `Select language`,
    p118: `Enter security code`,
    p119: `Your passport page or the back of your ID card`,
    p120: `ID card`,
    p121: `ID`,
    p122: `or the page of your passport where your photo is located`,
    p123: `To use the scanner, it's necessary to grant the corresponding permissions
    to the camera.`,
    p124: `Security Verification ​🔐`,
    p125: `To verify your number we've sent a code to your`,
    p126: `WhatsApp`,
    p127: `Enter it here:`,
    p128: `Enter the code`,
    p129: `Incorrect code, please verify and try again`,
    p130: `Back`,
    p131: `Continue`,
    p132: `Entry Date`,
    p133: `Exit Date`,
    p134: `Expiration Date`,
    p135: `Nationality`,

    p136: `By clicking`,
    p137: ` you agree to sign the form with your name, the short-term lease agreement`,
    p138: `Privacy Policies`,
    p139: `Save Signature`,
    p140: `Reservation Information`,
    p141: `RESERVATION:`,
    p142: `Entry`,
    p143: `Exit`,
    p144: `Pay`,
    p145: `Paid`,
    p146: `Verifying payment`,
    p147: `Check-in`,
    p148: `Reservation`,
    p149: `Services`,
    p150: `IDENTITY VERIFICATION`,
    p151: `Upload a selfie of your face, with good lighting please`,
    p152: `In this view you can upload a photo of the <strong>front of your ID`,
    p153: `In this view you can upload a photo of the <strong>back of your ID`,
    p154: `Open camera`,
    p155: `Take again`,
    p156: `Verify identity`,
    p157: `Continue`,
    p158: `Error verifying your identity.`,
    p159: `Please try again.
    You can take photos in a well-lit area and center your document properly.`,
    p160: `Process is mandatory*`,
    p161: `Retry`,
    p162: `Verifying Identity.`,
    p163: `Please wait ....`,
    p164: `Identity verified successfully`,
    p165: `You've verified your identity 🪪 successfully, almost done with the check-in process 😎`,
    p166: `To be consulted`,
    p167: `To request this service you must perform your check-in.`,
    p168: `Progress`,
    p169: `Document expiration date`,
    p170: `Entry date`,
    p171: `Exit date`,
    p172: `Phone`,
    p173: `Your document could not be scanned.`,
    p174: `The address does not belong to a reservation in the system`,
    p175: `Check-in Information`,
    p176: `Support`,
    p177: `Spanish (Spain)`,
    p178: `English`,
    p179: `Arabic`,
    p180: `ID`,
    p181: `Passport`,
    
};
