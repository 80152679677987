export const servicios = {
    text1: "Service requests list",
    text2: "Requests made by your clients",
    text3: "Requests list",
    text4: "All",
    text5: "Pending",
    text6: "Approved",
    text7: "Rejected",
    text8: "Paid",
    text9: "Service",
    text10: "Client",
    text11: "Phone",
    text12: "Created",
    text13: "Status",
    text14: "View order",
    text15: "Service request.",
    text16: "Request data",
    text17: "Request date:",
    text18: " at",
    text19: "Service information",
    text20: "Price:",
    text21: "Price per:",
    text22: "Category:",
    text23: "Provider:",
    text24: "Provider:",
    text25: "Internal Service",
    text26: "Service information",
    text27: "Service not found",
    text28: "Applicant information",
    text29: "Phone:",
    text30: "Email:",
    text31: "Document type:",
    text32: "Document number:",
    text33: "Close",
    text34: "Reject",
    text35: "Approve",
    text36: "Confirm Payment",
    text37: "Reject request",
    text38: "Approve request",
    text39: "Confirm applicant's payment",
    text40: "Are you sure you want to reject the service request?",
    text41: "Contact the service applicant before approving the request",
    text42: "By confirming the applicant's payment, the process will be complete",
    text43: "Reject",
    text44: "Approve request",
    text45: "I already confirmed the payment",
    text46: "Cancel",
    text47: "Updated successfully.",

    // New labels  

    p1: `Today`,
    p2: `Requested service`,
    p3: `Guest`,
    p4: `Request date`,
    p5: `There are no service requests made yet`,
    p6: `You haven't configured any services yet.`,
    p7: `Set up your accommodation services so your guests can make requests`,
    p8: `Set up service`,
    p9: `Consultation`,
    p10: `Discard service request`,
    p11: `Are you sure? The application data will be lost`,
    p12: `Discard`,
    p13: `Service request successfully deleted`,
};
